import React from "react";
import SeeMoreComp from "../component/SeeMoreComp";

const GameFi = () => {
  return (
    <div className="container py-5" style={{ maxWidth: "800px" }}>
      <h1 className="mb-4">Guild of fighter</h1>

      <h4 className="mb-5">Chapter I : The Legend of Muay Thai</h4>

      <p>
        As the technology gradually matured over the past five years, it is
        becoming an accepted fact in the cyberworld that blockchain technology
        is unstoppable and is here to stay. Every day brings another development
        in the ever-expanding blockchain world, specifically so with the minting
        of NFTs, the modern digital asset that unequivocally proves ownership.
        Along with the global evolution in the next stage of the digital
        revolution, Guild of Fighters (GOF), a martial arts NFT platform for the
        first Muay Thai (Thai boxing) themed game, built on the BNB blockchain
        is now ready to join the new meta world.
      </p>

      <p>
        There are - and will be - many varied entertaining experiences for GOF
        going forward. Players enter the NFT game to train, fight and battle
        each other in the player-owned economy, where players can truly own,
        buy, sell, and trade resources they earn playing through the game’s
        marketplace, such as giving their character a new skin, increasing
        accessories, and acquiring additional skills to power up.{" "}
      </p>

      <p>
        Outside of combat and training sessions, players spend their time in the
        NFTs marketplace, virtual land, and social media platforms where the GOF
        community converse, create, and transact to build a larger virtual
        world.
      </p>
      <SeeMoreComp
        mainImgPath="/img/gamefi1.png"
        subImgPath="/img/gamefi/gof.png"
        btnContent={
          <button
            className="btn style-btn text-white"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
            }}
          >
            See More →
          </button>
        }
      />
    </div>
  );
};

export default GameFi;
