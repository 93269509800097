import React from "react";
import { Route } from "react-router-dom";
import ImageSlider from "../component/ImageSlider";

const Home = () => {
  const images = [
    "/img/banner/banner1.png",
    "/img/banner/banner2.png",
    "/img/banner/banner3.png",
  ];

  const styles = {
    container: {
      marginTop: "50px",
    },
  };

  const displayAbout = (
    <div style={styles.container}>
      <h1 className="my-5 text-center">ABOUT US</h1>
      <div className="row">
        <div className="col-12 col-lg-6 text-center my-4 position-relative">
          <img
            src="img/aboutus.png"
            style={{ maxWidth: "420px", margin: "auto", width: "100%" }}
          />
          <div
            style={{
              position: "absolute",
              bottom: "-50px",
              right: "0",
              scale: "1",
            }}
          >
            <div className="position-relative">
              <img src="img/bid-bg.png" width="400px" />
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "0",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <div className="row ">
                  <div
                    className="col-7 text-start"
                    style={{ paddingLeft: "40px" }}
                  >
                    <div style={{ fontSize: "14px" }}>Metaverse Builder</div>
                    <div style={{ fontSize: "14px" }}>And GameFi Developer</div>
                  </div>
                  <div
                    className="col-5 align-items-end d-flex"
                    style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                  >
                    Auction Ends in
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="col-7">
                    <div className="d-flex align-items-center gap-2">
                      <img src="img/logo-circle.png" width="50px" />
                      <span>META PARK</span>
                      <img src="img/check.png" width="15px" />
                    </div>
                  </div>
                  <div className="col-5 p-0">
                    <div className="d-flex justify-content-around">
                      <div className="text-center">
                        <div>18</div>
                        <small>Hour</small>
                      </div>
                      <div className="text-center">
                        <div>18</div>
                        <small>Minute</small>
                      </div>
                      <div className="text-center">
                        <div>18</div>
                        <small>Second</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 align-items-center">
                  <div className="col-6">
                    <small className="">Reserve price</small>
                    <div className="d-flex align-items-center gap-1">
                      <img src="img/eth.png" width="15px" alt="eth" />
                      <div>ETH 9.3</div>
                      <small>($23,000)</small>
                    </div>
                  </div>
                  <div className="col-6">
                    <button className="btn style-btn2">Place Bid →</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 px-5   my-4">
          <p className="paragraph">
            Meta Park is a new era company with experience in blockchain,
            digital assets, metaverse, GameFi, and web 3.0 advice, investing,
            and creation. Beyond product or service development, with a team
            that has significant expertise across the entire blockchain
            industry, we can help in all aspects, including digital asset
            go-to-market strategies, tokenomics development, partnerships, PR,
            community management, and others that are crafted to suit customers’
            projects and goals.
          </p>
          <div>
            <button className="btn  style-btn ">Show More</button>
          </div>
        </div>
      </div>
    </div>
  );

  const displayBusiness = (
    <div style={styles.container}>
      <h1 className="text-center">BUSINESS</h1>
      <div className="row">
        <div className="col-12 col-lg-6 order-2 order-lg-1 px-5 my-5">
          <h2 className="mb-4">GAMEFI</h2>
          <p className="paragraph">
            The way gaming metaverses are being developed is opening up new
            revenue opportunities for the gaming communities, as well as the
            developers and investors. One of the key selling points of these
            investments is GameFi, a portmanteau of “game” and “finance,” which
            has essentially gamified financial systems to allow anyone to use
            crypto currencies, NFTs and virtual land, and in-game item sales.
            Developers aren’t just developing gaming experiences, but an entire
            metaverse ecosystem that offers multiple ways for creators to earn
            money via the sale of virtual items. And of course, META PARK has a
            development team that will push the destination beautifully.
          </p>
          <button className="btn style-btn">Show More</button>
        </div>
        <div className="col-12 col-lg-6 text-center order-1 order-lg-2 my-5">
          <img
            src="img/gamefi.png"
            style={{ width: "100%", margin: "auto", maxWidth: "540px" }}
          />
        </div>
      </div>
    </div>
  );

  const displayFintech = (
    <div style={styles.container}>
      <div className="row my-4">
        <div className="col-12 col-lg-6  my-5 text-center">
          <img
            src="img/fintech.png"
            style={{ width: "100%", margin: "auto", maxWidth: "540px" }}
          />
        </div>
        <div className="col-12 col-lg-6 px-5  my-5">
          <h2>FINTECH</h2>
          <p className="paragraph">
            Happy Day is the best payment gateway for ensuring customer safety
            and confidentiality. Make an impression and establish credibility in
            e-commerce with available payment methods. To be received via debit
            cards, credit cards, wallets, and etc. safely on the website and
            mobile app.
          </p>
          <button className="style-btn2">Show More</button>
        </div>
      </div>
    </div>
  );

  const displayLwc = (
    <div style={styles.container}>
      <div className="row my-4">
        <div className="col-12 col-lg-6 order-2 order-lg-1 ">
          <h2>LWC SUPER CHAMP BOXING</h2>
          <p className="paragraph">
            Are you ready to fight and become a legend? The most prestigious
            fight in Muay Thai, Lumpinee World Championship or LWC Super Champ
            is found to create legendary battles. The journey will begin in
            Thailand but eventually spans to the global scope. No matter their
            age, gender, or country of origin, we accept all warriors to wear
            the glory, honor, and legacy of Lumpinee Stadium. We, along with our
            audience worldwide, are waiting for you if you can fight and are
            sure to be the last who stand.
          </p>
          <button className="style-btn2">Show More</button>
        </div>
        <div className="col-12 col-lg-6 text-center order-1 order-lg-2 ">
          <img
            src="img/lwc.png"
            style={{ width: "100%", margin: "auto", maxWidth: "540px" }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <ImageSlider images={images} />
      <div className="container">
        {displayAbout}
        {displayBusiness}
        {displayFintech}
        {displayLwc}
      </div>
    </div>
  );
};

export default Home;
