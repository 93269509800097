import React from "react";
import DateTag from "../component/DateTag";

const Lwc = () => {
  return (
    <div className="container py-4">
      <div>
        <h1 className="text-center mb-4">LWC SUPER CHAMP</h1>

        <h2 className="mb-5">UPCOMING EVENTS</h2>

        <div>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/L9DsPgViPJE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            style={{ width: "100%", height: "600px", margin: "auto" }}
          ></iframe>
        </div>
      </div>
      <div className="fs-2 my-4 d-flex align-items-center">
        <span>TICKETS :</span>
        <a
          href="https://www.allticket.com/category/sport/boxing"
          target="_blank"
          className="ticket"
        >
          Click here to buy official tickets
        </a>
      </div>
      <div>
        <div className="position-relative m-auto">
          <img src="img/lwc1.png" className="w-100" alt="lwc1" />
          <div
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
          >
            <a
              href="https://www.allticket.com/category/sport/boxing"
              target="_blank"
              style={{
                textDecoration: "none",
              }}
            >
              <div
                className="text-center text-white fw-bold p-2"
                style={{
                  background: "#C20606",
                  fontSize: "clamp(1rem, 2.5vw, 2rem)",
                }}
              >
                CLICK <br /> HERE <br /> TO <br /> BUY <br /> TICKETS
              </div>
            </a>
          </div>
        </div>
        <div className="text-center my-5">
          <div className="rainbow">seating plan</div>
          <div>
            <img
              src="img/lwc2.png"
              alt="lwc2"
              style={{ width: "100%", maxWidth: "600px" }}
            />
          </div>
        </div>
      </div>

      <div style={{ maxWidth: "800px", margin: "auto" }}>
        <h1 className="text-center">TICKET PRICE</h1>
        <div className="lwc-border"></div>

        <div className="row my-4">
          <div className="col-6" style={{ borderRight: "3px solid #febd3c" }}>
            <div className="text-center mb-4">TICKET PRICE (THAI)</div>
            <RowText label="RINGSIDE" value="1,000 BAHT" />
            <RowText label="VIP" value="800 BAHT" />
            <RowText label="STAND" value="500 BAHT" />
          </div>

          <div className="col-6">
            <div className="text-center mb-4">TICKET PRICE (FOREIGNER)</div>
            <RowText label="RINGSIDE" value="1,500 BAHT" />
            <RowText label="VIP" value="1,200 BAHT" />
            <RowText label="STAND" value="800 BAHT" />
          </div>
        </div>
      </div>
      <div className="box-1">
        <div className="box-2">
          <div className="row align-items-center">
            <div
              className="col-6 col-md-6"
              style={{
                paddingLeft: "100px",
                paddingTop: "30px",
                fontSize: "40px",
                fontWeight: "bold",
              }}
            >
              <div>
                LINE-UP <br /> 2023
              </div>
            </div>
            <div className="col-6 col-md-6" style={{ paddingTop: "30px" }}>
              <div>SAT AT 06.00 PM. - 09.40 PM.</div>
              <div>AT Lumpinee Stadium, Ram-intra</div>
            </div>
          </div>

          <div className="lwc-line my-4" />

          <div className="row row-cols-1 row-cols-md-3 my-2 w-100 px-3 ">
            <div className="col  p-3">
              <h4>JANUARY</h4>
              <DateTag label="SATURDAY" date="14" />
              <DateTag label="SATURDAY" date="15" />
              <DateTag label="SATURDAY" date="12" />
            </div>

            <div className="col  p-3">
              <h4>FEBRUARY</h4>
              <DateTag label="SATURDAY" date="04" />
              <DateTag label="SATURDAY" date="11" />
              <DateTag label="SATURDAY" date="18" />
            </div>
            <div className="col  p-3 border border-warning border-3">
              <h4>MARCH</h4>
              <DateTag label="SATURDAY" date="04" />
              <DateTag label="SATURDAY" date="11" />
              <DateTag label="SATURDAY" date="18" />
              <DateTag label="SATURDAY" date="25" />
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="position-relative"
        style={{ maxWidth: "800px", width: "100%", margin: "auto" }}
      >
        <img
          src="/img/lwc/bg-tl.png"
          alt="bg"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "-1",
          }}
        />

        <div className="row align-items-center">
          <div
            className="col-6"
            style={{
              paddingLeft: "100px",
              paddingTop: "30px",
              fontSize: "40px",
              fontWeight: "bold",
            }}
          >
            <div>
              LINE-UP <br /> 2023
            </div>
          </div>
          <div className="col-6">
            <div>SAT AT 06.00 PM. - 09.40 PM.</div>
            <div>AT Lumpinee Stadium, Ram-intra</div>
          </div>
        </div>

        <div className="lwc-line my-4" />

        <div className="row my-2 w-100 px-3 ">
          <div className="col-4 p-3">
            <h4>JANUARY</h4>
            <DateTag label="SATURDAY" date="14" />
            <DateTag label="SATURDAY" date="15" />
            <DateTag label="SATURDAY" date="12" />
          </div>

          <div className="col-4 p-3">
            <h4>FEBRUARY</h4>
            <DateTag label="SATURDAY" date="04" />
            <DateTag label="SATURDAY" date="11" />
            <DateTag label="SATURDAY" date="18" />
          </div>
          <div className="col-4 p-3 border border-warning border-3">
            <h4>MARCH</h4>
            <DateTag label="SATURDAY" date="04" />
            <DateTag label="SATURDAY" date="11" />
            <DateTag label="SATURDAY" date="18" />
            <DateTag label="SATURDAY" date="25" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Lwc;

const RowText = ({ label, value }) => {
  return (
    <div className="d-flex justify-content-between align-items-center px-4 my-2">
      <div>{label}</div>
      <div style={{ color: "#FFBD3E" }}>{value}</div>
    </div>
  );
};
