import React from "react";

const CardAbout = ({ imgPath, content }) => {
  const styles = {
    img: {
      position: "absolute",
      top: "-50px",
      left: "25px",
      height: "100px",
    },
    container: {
      width: "300px",
      height: "350px",

      background: "transparent",
      //   background: "rgba(255, 255, 255, 0.1)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "3px",
      border: "1px solid rgba(255, 255, 255, 0.5)",
    },
    content: {
      position: "absolute",
      top: "60px",
      fontSize: "16px",
    },
  };

  return (
    <div className="card position-relative" style={styles.container}>
      <div className="card-body" style={styles.content}>
        {content}
      </div>
      <img src={imgPath} alt="img" style={styles.img} />
    </div>
  );
};

export default CardAbout;
