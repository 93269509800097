import React from "react";
import SeeMoreComp from "../component/SeeMoreComp";

const FinTech = () => {
  return (
    <div className="container py-5" style={{ maxWidth: "800px" }}>
      <div>
        <h2>Happy Day is Payment Gateway Solution</h2>
        <br></br>
        <p>
          E-Payment service provider that is allowed to operate business under
          Payment Systems Act B.E. 2560 (2017) which is regulated by the Bank of
          Thailand. Happy Day was developed best with professional team who
          understand the customer insight in order to solve the problem of
          financial transactions that are limited in many aspects, to support
          online businesses to have convenient, fast, secure, time-saving and
          easy-to-use payment methods. Moreover, there is an excellent financial
          management system to increase efficiency in financial management for
          online businesses ,and support entrepreneurs to grow unlimitedly.
          Therefore, we have designed a payment system which is suitable for
          using in every business. Whether it is SMEs, medium-sized businesses,
          or large-sized businesses.
        </p>
      </div>
      <div>
        <SeeMoreComp
          mainImgPath="/img/fintech/main.png"
          subImgPath="/img/fintech/sub.png"
          btnContent={
            <button
              className="btn style-btn text-white"
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              See More →
            </button>
          }
        />
      </div>
    </div>
  );
};

export default FinTech;
