import React from "react";
import SeeMoreComp from "../component/SeeMoreComp";

const Blockchain = () => {
  return (
    <div className="container py-5" style={{ maxWidth: "800px" }}>
      <div>
        <h2>NeteFi</h2>
        <p>
          Netefi Network is a fintech company running on the Blockchain and
          Coding networks through smart contracts responding to transaction
          transparency as happens immediately, without any intermediaries
          involved in between user’s activities. A network platform that
          connects all users in the world of decentralized finance with the
          investment specialists who are experts in investing in various forms
          of Defi to create a community in a form of smart contract. The rapid
          expansion of the community will arise through the Referral Program We
          believed that our platform services will satisfied all the demand for
          Fund Management in DEFI where fund manager, investors and referral
          shared the mutual benefits and Earn more profits by GOV token when
          user interract in services
        </p>
      </div>
      <div>
        <SeeMoreComp
          mainImgPath="/img/blockchain/main.png"
          subImgPath="/img/blockchain/sub.png"
          btnContent={
            <button
              className="btn btn-primary bg-gradient"
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              See More →
            </button>
          }
        />
      </div>
    </div>
  );
};

export default Blockchain;
