import React from "react";
import CardAbout from "../component/CardAbout";

const About = () => {
  const styles = {
    img: {
      objectFit: "contain",
    },
    qoute: {
      maxWidth: "800px",
      margin: "auto",
      marginBlock: "40px",
    },
  };

  const displayImages = (
    <div className="">
      <div style={{ marginBlock: "50px", textAlign: "center" }}>
        <h1>ABOUT US</h1>
      </div>
      <div className="row" style={{ maxWidth: "1000px", margin: "auto" }}>
        <div className="col text-center">
          <img
            src="img/about1.png"
            style={{ ...styles.img, maxWidth: "100%" }}
          />
        </div>
        <div className="col d-none d-lg-block">
          <div className="row g-3">
            <div className="col-6 ">
              <img src="img/about2.png" style={styles.img} />
            </div>
            <div className="col-6 ">
              <img src="img/about3.png" style={styles.img} />
            </div>
            <div className="col-6 ">
              <img src="img/about4.png" style={styles.img} />
            </div>
            <div className="col-6 ">
              <img src="img/about5.png" style={styles.img} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const displayText = (
    <div
      className="text-white my-5"
      style={{ maxWidth: "1000px", margin: "auto", fontSize: "20px" }}
    >
      <p>
        Metaverse gaming is opening up new revenue opportunities for gaming
        communities, as well as developers and investors, Especially GameFi
        which allows people to use cryptocurrencies, NFTs, virtual land, and
        in-game item sales.
      </p>

      <p>
        In this decentralized world, everyone is welcome to join. However,
        without a solid plan and experienced team, many projects cannot reach
        their final goal. That is when Meta Park came in to fulfill the
        significant but missing piece for a successful project.
      </p>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        These are what we have that customers can find none from others
      </div>
    </div>
  );

  const displayCards = (
    <div className="d-flex justify-content-between flex-wrap">
      <CardAbout
        content="A professional team to help customers from the beginning until receive desired outcome."
        imgPath="/img/about/icon1.png"
      />
      <CardAbout
        content="Inclusive service in every needed step includes planning, development (program, platform, payment gateway, etc.), creation (meaningful content, graphic, 3D, GameFi, NFTs, etc.), project execution, and marketing."
        imgPath="/img/about/icon2.png"
      />
      <CardAbout
        content="Partner with people or organizations associated with the industries, so customers’ projects will be advised, mentored, improved, or even collaborated with our partner professionally."
        imgPath="/img/about/icon3.png"
      />
      <CardAbout
        content="International expansion globally regardless of project planning, marketing, and international money transfer services that cover both Fiat and Cryptocurrencies."
        imgPath="/img/about/icon4.png"
      />
    </div>
  );

  const displayQoute = (
    <div className="text-center " style={styles.qoute}>
      If now you are ready to join in the “new” norm of the digital world by
      jumping NFTs, GameFi, and Metaverse phenomenon, do not hesitate to contact
      us for a consult, service, or collaboration at{" "}
      <span className="fst-italic text-decoration-underline">
        info@metaparkasia.com
      </span>
    </div>
  );

  return (
    <div className="container">
      {displayImages}
      {displayText}
      {displayCards}
      {displayQoute}
    </div>
  );
};

export default About;
