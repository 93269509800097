import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import GameFi from "./pages/GameFi";
import FinTech from "./pages/FinTech";
import Blockchain from "./pages/Blockchain";
import Lwc from "./pages/Lwc";
import Contact from "./pages/Contact";
import MainLayout from "./Layout/MainLayout";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gamefi" element={<GameFi />} />
        <Route path="/fintech" element={<FinTech />} />
        <Route path="/blockchain" element={<Blockchain />} />
        <Route path="/Lwc" element={<Lwc />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
    </Routes>
  );
};

export default App;
