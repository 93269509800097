import React from "react";

const SeeMoreComp = ({ mainImgPath, subImgPath, btnContent }) => {
  return (
    <div
      className="position-relative"
      style={{ width: "100%", maxWidth: "600px", margin: "auto" }}
    >
      <img src={mainImgPath} style={{ width: "100%", margin: "auto" }} />
      <div
        style={{
          maxWidth: "300px",
          width: "100%",
          position: "absolute",
          bottom: "-50px",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        <div className="position-relative">
          <img src={subImgPath} width="100%" />
          {btnContent}
        </div>
      </div>
    </div>
  );
};

export default SeeMoreComp;
