import React from "react";

const DateTag = ({ date, label }) => {
  return (
    <div className="d-flex justify-content-between align-items-center my-2">
      <div style={{ color: "#ffbd3e", fontWeight: "bold" }}>{date}</div>
      <div>{label}</div>
    </div>
  );
};

export default DateTag;
