import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const displayPartner = (
    <div
      // className="container-sm"
      style={{
        border: " 3px solid gray",
        marginTop: "100px",
        marginInline: "auto",
        padding: "40px",
      }}
    >
      <h1 className="text-center">PARTNER</h1>
      <div className="row row-cols-auto row-cols-md-4">
        <div className="col m-auto text-center">
          <img
            src="img/partner1.png"
            style={{
              width: "120px",
              height: "120px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner2.png"
            style={{
              width: "100px",
              height: "80px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner3.png"
            style={{
              width: "100px",
              height: "80px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner4.png"
            style={{
              width: "80px",
              height: "90px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner5.png"
            style={{
              width: "170px",
              height: "100px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner6.png"
            style={{
              width: "80px",
              height: "80px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner7.png"
            style={{
              width: "150px",
              height: "100px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner8.png"
            style={{
              width: "200px",
              height: "100px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner9.png"
            style={{
              width: "200px",
              height: "100px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner10.png"
            style={{
              width: "150px",
              height: "150px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner11.png"
            style={{
              width: "180px",
              height: "100px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
        <div className="col m-auto text-center">
          <img
            src="img/partner12.png"
            style={{
              width: "200px",
              height: "100px",
              objectFit: "contain",
              filter: "grayscale(1)",
            }}
          />
        </div>
      </div>
    </div>
  );

  const displayAbout = (
    <div className="row my-5">
      <div className="col-12 col-md-3">
        <img src="/img/logo.png" width="150px" />
        <div className="text-white my-3">
          Metaverse Builder and GameFi Developer
        </div>
        <div className="d-flex justify-content-start mb-3">
          <a href="https://www.facebook.com/metaparkasia" target="_blank">
            <img src="/img/social/facebook.png" width="30px" className="me-2" />
          </a>
          <a href="https://twitter.com/Metaparkasia" target="_blank">
            <img src="/img/social/twitter.png" width="30px" className="me-2" />
          </a>
          <a href="https://medium.com/@metaparkasia" target="_blank">
            <img src="/img/social/some.png" width="30px" className="me-2" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCQ_NZ6lm_u5Vm0dMq1TBPpA"
            target="_blank"
          >
            <img src="/img/social/youtube.png" width="30px" className="me-2" />
          </a>
        </div>
      </div>
      <div className="col-4 col-md-2">
        <h6>About</h6>
        <div className="ul">
          <Link to="/">Home</Link>
          <Link to="/">Project</Link>
          <Link to="/">Partner</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
      <div className="col-4 col-md-2">
        <h6>Follow us</h6>
        <div className="ul">
          <a href="https://www.facebook.com/metaparkasia" target="_blank">
            Facebook
          </a>
          <a href="https://twitter.com/Metaparkasia" target="_blank">
            Twitter
          </a>
          <a href="https://medium.com/@metaparkasia" target="_blank">
            Medium
          </a>
          <a
            href="https://www.youtube.com/channel/UCQ_NZ6lm_u5Vm0dMq1TBPpA"
            target="_blank"
          >
            youtube
          </a>
        </div>
      </div>
      <div className="col-12 col-md-5">
        <h6>Location</h6>
        <div
          style={{
            fontSize: "12px",
          }}
        >
          89 Room 12A01 AIA Capital Center Building, Ratchadaphisek Road, Din
          Daeng Subdistrict, Din Daeng District, Bangkok
        </div>
        <br />
        <div
          style={{
            fontSize: "12px",
          }}
        >
          info@metaparkasia.com
        </div>
      </div>
    </div>
  );

  const displayFooter = (
    <div
      className="nav-footer d-flex justify-content-between align-items-center "
      style={{ height: "80px", paddingInline: "10%" }}
    >
      <div style={{ background: "initial" }}>
        © 2023 meta park. All rights reserved
      </div>
      <div className="" style={{ background: "initial" }}>
        <a
          href="/"
          className="text-decoration-none text-white "
          style={{ background: "initial", fontSize: "12px" }}
        >
          Terms & Conditions
        </a>
        <span className="mx-1" style={{ background: "initial" }}>
          |
        </span>
        <a
          href="/"
          className="text-decoration-none text-white "
          style={{ background: "initial", fontSize: "12px" }}
        >
          Privacy Policy
        </a>
        <span className="mx-1" style={{ background: "initial" }}>
          |
        </span>
        <a
          href="/"
          className="text-decoration-none text-white "
          style={{ background: "initial", fontSize: "12px" }}
        >
          Sitemap
        </a>
      </div>
    </div>
  );

  return (
    <div>
      <div className="container" style={{ maxWidth: "1000px" }}>
        {displayPartner}
        {displayAbout}
      </div>

      {displayFooter}
    </div>
  );
};

export default Footer;
