import React from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet-async";

const MainLayout = () => {
  const { pathname } = useLocation();

  const styles = {
    textMenu: {
      fontSize: "12px",
    },
  };

  const menuList = [
    {
      label: "HOME",
      path: "/",
      children: [],
    },
    {
      label: "ABOUT",
      path: "/about",
      children: [],
    },
    {
      label: "BUSINESS",
      path: "/",
      children: [
        { label: "GameFi", path: "/gamefi" },
        { label: "FinTech", path: "/fintech" },
        { label: "Blockchain", path: "/blockchain" },
      ],
    },
    {
      label: "LWC SUPER CHAMP",
      path: "/lwc",
      children: [],
    },
    {
      label: "CONTACT",
      path: "/contact",
      children: [],
    },
  ];

  const displayTopBar = (
    <nav className="navbar navbar-expand-lg shadow">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <div>
            <img
              src="img/logo.png"
              style={{
                width: "100%",
                maxWidth: "150px",
              }}
            />
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            {menuList?.map((item) => {
              return item.children.length ? (
                <li className="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle menu-item ${
                      !!item.children.find((el) => el.path === pathname) &&
                      "menu-active"
                    }`}
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={styles.textMenu}
                  >
                    {item.label}
                  </a>
                  <ul className="dropdown-menu bg-dark p-0">
                    {item.children.map((subItem) => {
                      return (
                        <li
                          className={`menu-item-dropdown mx-0 px-0 py-1 ${
                            pathname === subItem.path
                              ? "menu-active-dropdown"
                              : ""
                          }`}
                        >
                          <Link
                            className="dropdown-item"
                            to={subItem.path}
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {subItem.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ) : (
                <li
                  className={`nav-item menu-item  ${
                    pathname === item.path ? "menu-active" : ""
                  }`}
                >
                  <Link
                    className="nav-link"
                    to={item.path}
                    style={styles.textMenu}
                  >
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );

  return (
    <div>
      <Helmet>
        <title>Meta Park</title>
        <meta property="title" content="Meta Park" />
        <meta
          property="description"
          content="Meta Park is a new era company with experience in blockchain, digital assets, metaverse, GameFi, and web 3.0 advice, investing, and creation."
        />
        <meta property="image" content="/logo-circle.png" />
        <meta property="url" content="https://metaparkasia.com/" />
        <meta property="type" content="website" />

        <meta property="og:title" content="Meta Park" />
        <meta
          property="og:description"
          content="Meta Park is a new era company with experience in blockchain, digital assets, metaverse, GameFi, and web 3.0 advice, investing, and creation."
        />
        <meta property="og:image" content="/logo-circle.png" />
        <meta property="og:url" content="https://metaparkasia.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      {displayTopBar}
      <div className="container">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
