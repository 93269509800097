import React, { useState } from "react";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [service, setService] = useState("");
  const [body, setBody] = useState(``);

  return (
    <div className="py-4">
      <h1
        style={{
          marginBlock: "100px",
        }}
        className="text-center"
      >
        Contact
      </h1>

      <div className="position-relative">
        <img src="/img/contact/bg-1.png" alt="bg" width="100%" />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "40px",
            transform: "translate(0, -50%)",
            width: "500px",
          }}
        >
          <div
            style={{
              fontSize: "52px",
              fontWeight: "bold",
              lineHeight: "1",
            }}
          >
            <span
              style={{
                fontSize: "70px",
              }}
            >
              It’s a
            </span>{" "}
            jungle out there.
          </div>
          <div
            className="mt-4"
            style={{
              fontSize: "32px",
              lineHeight: "1",
            }}
          >
            Are your innovative ideas getting lost in the crowd?
          </div>
          <div className="mt-4">
            Let our innovative team build and make your digital products shine.
          </div>
        </div>
      </div>

      <div className="container">
        <div className="text-center mt-5 fs-1">Please Tell Us Your Ideas</div>

        <div className="text-center my-3 fs-4" style={{ fontWeight: "300" }}>
          We will get back to you within 24 hours!
        </div>

        <div
          className="row border border-3 p-4"
          style={{ width: "100%", margin: "auto", maxWidth: "800px" }}
        >
          <div className="col-12 col-md-6">
            <div className="my-3">Email</div>
            <input
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
              type="text"
              class="form-control"
              placeholder="samantha@email.com"
            ></input>
          </div>
          <div className="col-12 col-md-6">
            <div className="my-3">Phone No.</div>
            <input
              value={phoneNumber}
              onChange={(ev) => setPhoneNumber(ev.target.value)}
              type="text"
              class="form-control"
              placeholder="+1 (123) 456-7890"
            ></input>
          </div>
          <div className="col-12">
            <div className="my-3">Services</div>
            <input
              type="text"
              class="form-control my-3"
              placeholder=""
              value={service}
              onChange={(ev) => setService(ev.target.value)}
            ></input>
            <div className="text-center">
              <a
                className="btn style-btn mt-2 mb-4   py-2 px-4"
                href={`mailto:metapark8@gmail.com?subject=${service}&body=${`From : ${email}
                Phone : ${phoneNumber}
                `}`}
              >
                Send
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="text-center  fs-1 my-5">Follow Us</div>
        </div>
        <div className="d-flex justify-content-center my-5">
          <img src="img/f.png" alt="followUs1" className="mx-4" />
          <img src="img/tw.png" alt="followUs2" className="mx-4" />
          <img src="img/m.png" alt="followUs3" className="mx-4" />
          <img src="img/y.png" alt="followUs4" className="mx-4" />
        </div>
      </div>

      {/* <EmailForm /> */}
    </div>
  );
};

export default Contact;
