import React from "react";
import Slider from "react-slick";

const ImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="position-relative">
      <Slider {...settings}>
        {images?.map((el, index) => {
          return (
            <div>
              <img src={el} alt={`banner-${index + 1}`} className="w-100" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ImageSlider;
